
import { computed, defineComponent } from 'vue';
import Card from '@/core/components/UI/Card.vue';
import ComboDisponibilidade from './ComboDisponibilidade.vue';
import ComboRegraSemEstoque from './ComboRegraSemEstoque.vue';

export default defineComponent({
  name: 'ProdutoEstoque',
  props: {
    gerenciarEstoque: {
      type: Boolean,
      required: true,
    },
    disponibilidade: {
      type: Number,
      required: true,
    },
    regraSemEstoque: {
      type: Number,
      required: true,
    },
    quantidadeEstoque: {
      type: Number,
      required: true,
    },
  },
  components: {
    Card,
    ComboDisponibilidade,
    ComboRegraSemEstoque,
  },
  emits: ['update:gerenciarEstoque', 'update:disponibilidade', 'update:regraSemEstoque', 'update:quantidadeEstoque'],
  setup(props, { emit }) {
    const computedGerenciarEstoque = computed({
      get: () => props.gerenciarEstoque,
      set: (valor: boolean) => {
        emit('update:gerenciarEstoque', valor);
      },
    });

    const computedDisponibilidade = computed({
      get: () => props.disponibilidade,
      set: (valor: number) => {
        emit('update:disponibilidade', valor);
      },
    });

    const computedRegraSemEstoque = computed({
      get: () => props.regraSemEstoque,
      set: (valor: number) => {
        emit('update:regraSemEstoque', valor);
      },
    });

    const computedQuantidadeEstoque = computed({
      get: () => props.quantidadeEstoque,
      set: (valor: number) => {
        emit('update:quantidadeEstoque', valor);
      },
    });

    return {
      props,
      computedGerenciarEstoque,
      computedDisponibilidade,
      computedRegraSemEstoque,
      computedQuantidadeEstoque,
    };
  },
});
